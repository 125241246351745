<template>
  <div class="service-layout">
    <b-container>
      <h1 class="text-center mb-4"><span class="blue-text">{{title}}</span> в Красноярске</h1>

      <b-row>
        <div class="col-lg-5 service-description">
          {{text}}
        </div>
        <div class="col-lg-7 mt-3 mt-lg-0 text-center">
          <b-img class="service-pic" :src="pic" fluid :alt="title + ' в Красноярске'"></b-img>
          <button v-b-modal.call-modal class="order-btn mt-5">Заказать {{String(title).toLowerCase()}}</button>
        </div>
      </b-row>
      <h3 class="text-center mt-5">Полный прайс на услуги</h3>
      <b-table hover class="mt-4" :items="table" :fields="[{key: 'title', label: 'Название'}, {key: 'cost', label: 'Цена, р.'}]">
      </b-table>

      <div class="text-center">
        <router-link to="/calculator" class="text-center">
          <button class="outline-btn mt-4">Рассчитать стоимость</button>
        </router-link>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ServiceLayout",
  data() {
    return {
      title: this.$route.meta.name,
      text: this.$route.meta.text,
      pic: this.$route.meta.pic,
      table: this.$route.meta.table
    }
  }
}
</script>

<style scoped lang="scss">
.service-layout{
  padding-top: 110px;
}

.service-description{
  font-size: 20px;
}

.order-btn{
  background: linear-gradient(93.86deg, #3CB3FF -1.19%, #49B8FF -1.18%, #98D7FF 133.59%);
  border-radius: 50px;
  border: none;
  outline: none;
  color: white;
  padding: 10px 30px;
  font-size: 24px;
}

.outline-btn{
  background: none;
  border-radius: 50px;
  border: var(--blue-main) 2px solid;
  outline: none;
  color: var(--blue-main);
  padding: 10px 30px;
  font-size: 20px;
}

@media screen and (max-width: 768px){
  .order-btn{
    font-size: 18px;
  }
}


.service-pic{
  border-radius: 20px;
}
</style>